/* General styles for Owl Carousel navigation */
.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: block !important;
  }
  .owl-nav {
    /* position: relative; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: -50px;
    z-index: 10;
  }
  
  .owl-nav .prev {
    position: absolute;
    border: 2px solid #797979;
    left: -44px;
    top: 50%;
    transform: translateY(-50%);
    color: #0f0e0e;
    border-radius: 50%;
    font-size: 30px !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 40px;
    height: 40px;
    line-height: 18px !important;
    border-radius: 50% !important;
  }
  
  .owl-nav .next {
    position: absolute;
    border: 2px solid #797979;
    right: -44px;
    top: 50%;
    transform: translateY(-50%);
    color: #0f0e0e;
    border-radius: 50%;
    font-size: 30px !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 40px;
    height: 40px;
    line-height: 18px !important;
    border-radius: 50% !important;
  }
  
  .owl-nav .prev:hover,
  .owl-nav .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff
  }
  
  @media (max-width: 768px) {
    .owl-nav .prev {
        left: -20px;
    }
  
    .owl-nav .next {
        right: -20px;
    }
  }