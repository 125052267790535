
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}

/* Shapes & Animation*/
.floating {
  position: absolute;
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  
}



.floating.meditationGirl {
  display: inline-block;
  width: 65px;
  height: 65px;
  z-index: 9
}

.floating.meditationBoy {
  display: inline-block;
  width: 65px;
  height: 65px;
  z-index: 9
}



@keyframes floating {
  0% {
    transform: translateY(0%) rotate(-55deg);
  }

  50% {
    transform: translateY(300%) rotate(55deg);
  }

  100% {
    transform: translateY(0%) rotate(-55deg);
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%);
  }

  50% {
    -webkit-transform: translateY(300%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}







@media (min-width: 540px) {
  .container {
      max-width:540px
  }
}

@media (min-width: 640px) {
  .container {
      max-width:640px;
      padding-right: 1rem;
      padding-left: 1rem;
  }
}

@media (min-width: 768px) {
  .container {
      max-width:768px
  }
}

@media (min-width: 992px) {
  .container {
      max-width:992px
  }
}

@media (min-width: 1024px) {
  .container {
      max-width:1024px;
      padding-right: 45px;
      padding-left: 45px
  }
}

@media (min-width: 1280px) {
  .container {
      max-width:1280px;
      padding-right: 5rem;
      padding-left: 5rem
  }
}

@media (min-width: 1536px) {
  .container {
      max-width:1536px;
      padding-right: 13rem;
      padding-left: 13rem
  }
}